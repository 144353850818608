<template lang="pug">
main
    carousel-header
    bodyworks-carousel
    recent-products
    //works-component
</template>

<script>
import { mapActions } from "vuex";
import CarouselHeader from "@/components/CarouselHeader"
import BodyworksCarousel from "@/components/bodyworks/BodyworkCarousel";
import RecentProducts from "@/components/product/Recent";
//import WorksComponent from "@/components/WorksComponents";

export default {
    name: "Inicio",
    components: {
        CarouselHeader,
        //WorksComponent,
        BodyworksCarousel,
        RecentProducts,
    },
    data() {
        return {
        loginObj: {
            email: "",
            password: "",
        },
        };
    },
    created() {
        this.checkIsRole();
    },
    methods: {
        ...mapActions("auth", ["checkIsRole"]),
        onGet() {
        this.$axios
            .get("/v1/getUser")
            .then(() => {
            this.$bvToast.toast("Carga de datos con éxito", {
                title: "Bien",
                variant: "success",
                solid: true,
            });
            })
            .catch(() => {
            this.$bvToast.toast("Error al cargar datos del usuario", {
                title: "Error de carga",
                variant: "danger",
                solid: true,
            });
            });
        },
    },
};
</script>
