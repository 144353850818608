import { Carousel } from 'vue-carousel';
<template lang="pug">
#carousel-header
    b-carousel(
        id="carousel-1"
        v-model="slide"
        :interval="40000"
        controls
        indicators
        background="#ababab"
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333;"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
    )
        b-carousel-slide
            template(#img)
                img(
                    class="d-block img-fluid w-100"
                    width="1024"
                    height="480"
                    src="@/assets/img/slider-1.jpg"
                    alt="image slot 1"
                )
            h2 Empieza una gran aventura con un nuevo vehículo
            p Explora nuestra amplia selección de automóviles de alta calidad y haz una compra o venta segura en nuestra plataforma.
        b-carousel-slide
            template(#img)
                img(
                    class="d-block img-fluid w-100"
                    width="1024"
                    height="480"
                    src="@/assets/img/slider-2.jpg"
                    alt="image slot 2"
                )
            h2 Descubre tu vehículo perfecto
            p Compra o vende con confianza en nuestra plataforma.
        b-carousel-slide
            template(#img)
                img(
                    class="d-block img-fluid w-100"
                    width="1024"
                    height="480"
                    src="@/assets/img/slider-3.jpg"
                    alt="image slot 3"
                )
</template>
<script>
export default {
    name: "Carouselheader",
    data() {
        return {
            slide: 0,
            sliding: null
        }
    },
    methods: {
        onSlideStart(slide) {
            this.sliding = true
        },
        onSlideEnd(slide) {
            this.sliding = false
        }
    }
}
</script>
<style lang="scss">
@import "@/assets/scss/_variables";
.carousel-caption {
    bottom:0;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    margin-top: -15%;
    right: auto;
    text-align: left;
    top:0;
    width: 70%;
    z-index: 2;
    @media (max-width:1280px) {
        margin-top: 0%;
        width: 75%;
    }
    h2 {
        font-weight: 600!important;
        font-size: 2.5rem;
        color: $primary;
    }
    p {
        font-size: 1.3rem;
    }
}
.carousel-indicators {
    z-index: 3;
}
</style>